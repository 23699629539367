import { IPManagementAdd, IPManagementList, UI } from '@fogldn-ltd/components';
import React from 'react';

const NotFound = () => {
  return (
    <UI.Box py={6} px={3} textAlign="center">
      <UI.Typography variant="h2" fontWeight={600} color="error.main" textAlign="center">
        404
      </UI.Typography>
      <UI.Typography variant="h4" fontWeight={400} textAlign="center">
        Not Found
      </UI.Typography>
    </UI.Box>
  )
}

export default NotFound;