import * as React from "react"
import { Router } from "@reach/router"
import { LoadingOverlay } from "@fogldn-ltd/components";
import { useBootstrap } from "@fogldn-ltd/state";

import Layout from "../../dashboard/components/Layout";
import App from "../../dashboard/components/App";

import Home from "../../dashboard/pages/home";
import Manage from "../../dashboard/pages/manage";
import NotFound from "../../dashboard/pages/notfound";
import SSRCheck from "../../components/SSRCheck";

const DashboardPage = () => {
  const bootstrap = useBootstrap();

  React.useEffect(() => {
    bootstrap.load();
  }, []);

  return (

    <SSRCheck>
      {(ssr) => (
        <>
          <LoadingOverlay openOverride={ssr} />
          {(!ssr && bootstrap.loaded) && (
            <Layout>
              <Router basepath="/dashboard">
                <Home path="/" />
                <Manage path="/manage" />
                <NotFound default />
              </Router>
            </Layout>
          )}
        </>
      )}
    </SSRCheck>
  )
}

export default () => (
  <App>
    <DashboardPage />
  </App>
)
