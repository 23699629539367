import { createTheme } from '@fogldn-ltd/components';

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(","),
  },
  palette: {
    primary: {
      main: "#F7B740",
    },
    secondary: {
      main: "#3564e6"
    },
    background: {
      default: "#161D24",
      paper: "#29323E"
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      }
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent'
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
          background: 'linear-gradient(to bottom, #29323E, #161D24)'
        },
        html: {
          background: '#29323E',
        }
      }
    }
  }
});

export default theme;