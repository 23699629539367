import { IPManagementAdd, IPManagementList, UI } from '@fogldn-ltd/components';
import React from 'react';

const Home = () => {
  return (
    <UI.Grid container spacing={2}>
      <UI.Grid item xs={4}>
        <UI.TransitionIn delay={0}>
          <UI.Card>
            <UI.Box p={2}>
              <UI.Typography mb={1} variant="h5">
                Add IP
              </UI.Typography>
              <IPManagementAdd />
            </UI.Box>
          </UI.Card>
        </UI.TransitionIn>
      </UI.Grid>
      <UI.Grid item xs={8}>
        <UI.TransitionIn delay={50}>
          <UI.Card>
            <IPManagementList />
          </UI.Card>
        </UI.TransitionIn>
      </UI.Grid>
    </UI.Grid>
  )
}

export default Home;