import { UI } from '@fogldn-ltd/components';
import React from 'react';

const Home = () => {
  return (
    <>
      <UI.Grid container spacing={2}>
        <UI.Grid item xs={2.5}>
          <UI.TransitionIn>
            <UI.Card>
              <UI.Box p={3}>
                <UI.Gauge percentage={75}>
                  rahhhh
                </UI.Gauge>
              </UI.Box>
            </UI.Card>
          </UI.TransitionIn>
          <UI.Box mt={2}>
            <UI.TransitionIn delay={50}>
              <UI.Card>
                <UI.Box px={3} py={2}>
                  <UI.Typography variant="card">
                    Topup Data
                  </UI.Typography>
                </UI.Box>
                <UI.Divider />
                <UI.Box p={3}>
                  <UI.Typography variant="h4" fontWeight={500} lineHeight={0}>
                    50
                    <UI.Typography component="span" variant="h5">GB</UI.Typography>
                  </UI.Typography>

                  <UI.Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                    Topup
                  </UI.Button>
                </UI.Box>
              </UI.Card>
            </UI.TransitionIn>
          </UI.Box>
        </UI.Grid>
        <UI.Grid item xs={9.5}>
          <UI.TransitionIn delay={50}>
            <UI.Card>
              <UI.Box px={3} py={2} display="flex" justifyContent="space-between">
                <UI.Typography variant="card">Data Usage</UI.Typography>
                <UI.UsageGraphLegend />
              </UI.Box>
              <UI.Divider />
              <UI.Box sx={{ backgroundColor: 'background.default' }}>
                <UI.AspectRatio ratio={16 / 8}>
                  <UI.UsageGraph
                    days={[
                      { bytes: 3, up: 1, down: 2, date: Date.now().valueOf() },
                      { bytes: 20, up: 12, down: 8, date: Date.now().valueOf() },
                      { bytes: 10, up: 1, down: 2, date: Date.now().valueOf() },
                      { bytes: 10, up: 1, down: 2, date: Date.now().valueOf() },
                      { bytes: 10, up: 1, down: 2, date: Date.now().valueOf() },
                      { bytes: 10, up: 1, down: 2, date: Date.now().valueOf() },
                      { bytes: 10, up: 1, down: 2, date: Date.now().valueOf() }
                    ]}
                  />
                </UI.AspectRatio>

              </UI.Box>
            </UI.Card>
          </UI.TransitionIn>
        </UI.Grid>
      </UI.Grid>

      <UI.Box py={4} />
      <UI.Divider />
      <UI.Box py={4} />

      <UI.Card>
        Test
        <UI.Box mb={4} />
      </UI.Card>
      <UI.Box pb={8} />
    </>
  )
}

export default Home;