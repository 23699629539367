import React from 'react';
import { UI } from '@fogldn-ltd/components';
import { useGeneralSlice } from '@fogldn-ltd/state';
import logo from '../../../images/logo.png'

const Layout = ({ children }) => {
  const general = useGeneralSlice();
  return (
    <>
      <UI.Header
        title={general.name}
        logo={logo}
        routes={[
          { name: "Dashboard", href: "/dashboard" },
          { name: "Manage", href: "/dashboard/manage" },
          { name: "Account", href: "/dashboard/account" },
          { name: "Store", href: "/dashboard/store" },
        ]}
      />
      <UI.Container>
        <UI.Box pt={5} />
        {children}
      </UI.Container>
    </>
  );
}

export default Layout;