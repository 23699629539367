import React from 'react';
import { GlobalProvider, LoadingOverlay } from '@fogldn-ltd/components';

import theme from "../../../config/theme";
import config from "../../../config/general";
import components from "../../../config/components";

/**
 * Application entry, configures whitelabel.
 */
const App = ({ children }) => (
  <GlobalProvider
    theme={theme}
    config={config}
    components={components}
  >
    {children}
  </GlobalProvider>
);

export default App;